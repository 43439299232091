<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Schedule a Checklist</h4>
                        </b-col>
                        <b-col class="text-right">
                            
                                        <b-button variant="red" squared class="mr-2" @click="deleteSchedule()">Delete</b-button>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" v-if="state === 'show'">
                            <sheduleChecklist />
                        </b-col>
                        <b-col cols="12" v-if="state === 'initiliazing' || state === 'loading'">
                            <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                        </b-col>
                    </b-row>
                    
                </b-card>
            </b-col>
        </b-row>
        
        
    </div>
</template>

<script>
import { bus } from '../../main'
import { mapMutations, mapActions, mapState } from 'vuex'
import sheduleChecklist from '../../components/scheduleChecklist.vue'
export default {
    components: { sheduleChecklist },
    data: () => ({
        state: 'initiliazing'
    }),
    created(){
        if(this.selectedScheduledChecklist === null){
            this.$router.back()
        }
        this.setBreadcrumb([
            {
                text: 'Scheduled Checklists'
            },
            {
                text: 'Update',
                active: true
            }
        ])

        this.getScheduleChecklist()
        .then(() => {
            this.state = 'show'
        })

        bus.$off()
        
        bus.$on('saveSchedule', () => {
            this.state = 'loading'
            this.updateNewSchedule()
            .then((res) => {
                
                //console.log('res', res)
                if (res.data.alreadyScheduled === true) {
                    this.clearMessage()
                    this.addWarningMessage('This schedule based on persons, topic and due date already exsist. And cannot be scheduled with the same parameters')
                    this.state = 'show'
                } else {
                    this.$router.push({path: '/checklist/schedule/search'})
                }
            })
            .catch(() => {
                this.state = 'show'
            })
        })
    
    },
    methods:{
        ...mapActions(['updateNewSchedule', 'getScheduleChecklist', 'deleteChecklistSchedule']),
        ...mapMutations('breadcrumbService', ['setBreadcrumb']),
        ...mapActions('notificationService', ['addWarningMessage', 'clearMessage']),
        
        deleteSchedule(){
            this.$store.commit('setDeleteScheduleChecklistRequest', this.selectedScheduledChecklist.checkListScheduleID)
            this.deleteChecklistSchedule()
            .then(() => {
                this.$router.back()
            })
        },
    },
    computed:{
        ...mapState(['selectedScheduledChecklist'])
    }
}
</script>
